'use strict'

const self = {
  container : document.querySelector('.accordion'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    
  },

  registerEvents : () => {
    const items = [...document.querySelectorAll('.accordion-item')]

    items.forEach((item) => {
      const header = item.childNodes[1].childNodes[1];
      header.addEventListener('click', e => {
        item.classList.toggle('is-active');
        })
      })
  }

}

export default self
