'use strict';

// Dependancies
import "babel-polyfill"

// Modules
import Accordion from './modules/_accordion'

// Global Functionality

// Conditional Functionality
const m = [
  // { o : MODULE,                 i : MODULE.container },
  {
    o: Accordion,
    i: Accordion.container
  },
].map( a => (a.i) ? a.o.init() : null )
